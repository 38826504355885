import React, { useState } from 'react'
import axios from 'axios'

const Newsletter = props => {

  const defMessage = <>
    <p>Za primanje obavijesti o natječajima moraš poslati svoju e-mail adresu.</p>
    <p>Tvoju e-mail adresu ćemo koristiti isključivo za slanje obavijesti o natječajima.</p>
  </>

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(defMessage);

  const token = document.querySelector('[name=csrf-token]').content
  const send = () => {
    axios.post('/newsletter', {email: email, authenticity_token: token})
    .then(function (response) {
      setMessage(<p>{response.data}</p>);
    }).catch(function (error) {
      console.log(error);
    });
  }

  return <div id="newsletter">
    <div>
      <h4>Prijavi se na obavijesti o natječajima</h4>
      {message}
      <label htmlFor="email">e-mail:</label>
      <input id="email" type="text" placeholder="adress@domena.com" value={email} onChange={e => setEmail(e.target.value)} />
    </div>
    <button onClick={send}>prijavi se</button>
  </div>
}

export default Newsletter
