import React, { useState } from 'react'
import axios from 'axios'
require('./payment_slip/payment_slip.css')

const PaymentSlip = props => {

  const address = props.recipient.address.split('|')
  const [donor, setDonor] = useState('Anonimni donator');
  const [amount, setAmount] = useState(props.min_amount);
  const [barcode, setBarcode] = useState();
  const [buttonTxt, setButtonTxt] = useState('Generiraj Barkod');

  const generateBarcode = () => {
    setButtonTxt(' ... ')
    const barcode_data = {
      "renderer": "svg",
      "options": {
          "scale": 3,
          "ratio": 3,
          "color": "#2c3e50"
      },
      "data": {
        "amount": parseFloat(amount),
        "sender": {
        "name": "",
        "street": "",
        "place": ""
      },
      "receiver": {
        "name": "",
        "street": "",
        "place": "",
        "iban": props.iban,
        "model": "99",
        "reference": ""
      },
      "purpose": "WEBI",
      "description": props.description
      }
    }
    axios.post("/barcode", barcode_data)
      .then(function (response) {
        setBarcode(response.data)
        setButtonTxt('Generiraj Barkod')
      }).catch(function (error) {
        console.log(error);
    });
  }

  return (<div>
    <form id='donation'>
      <label>Vaše ime i prezime
        <input type="text" value={donor} onChange={e => setDonor(e.target.value)} />
      </label>
      <label>Iznos donacije (kn)
        <input type="number" min="1" value={amount} onChange={e => setAmount(e.target.value)} />
      </label>
    </form>
    <div id='payment_slip'>
      <div id='name'>{donor}</div>
      <div id='recipient'>{props.recipient.name}<br /><br />{address[0]}<br />{address[1]}</div>
      <div id='amount'>{amount*100}</div>
      <div id='iban'>{props.iban}</div>
      <div id='model'>HR99</div>
      <div id='reference_number'></div>
      <div id='description'>{props.description}</div>
    </div>
    <div className='barcode link_container'>
      <button className='button black' onClick={generateBarcode}>{buttonTxt}</button>
      <div id='barcode' dangerouslySetInnerHTML={{__html: barcode}} />
    </div>
  </div>)
}

export default PaymentSlip
