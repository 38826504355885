/* eslint no-console:0 */
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const css = require.context("../../assets/stylesheets")
css.keys().forEach(css)

const js = require.context("../../assets/javascripts")
js.keys().forEach(js)

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
