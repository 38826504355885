$(document).ready(function() {
  $("#hamburger_icon").click(function() {
    if ($(".hamburger").hasClass("is-active")) {
      $(".hamburger").removeClass("is-active");
      $(".main_nav").removeAttr("id", "mobile");
      $("body").css("overflow-y", "auto" );
      $("#hamburger_icon").css({
        "position" : "relative",
        "top" : "-20px",
        "right" : "-20px"
      });
    } else {
      $(".hamburger").addClass("is-active");
      $(".main_nav").attr("id", "mobile");
      $("body").css("overflow-y", "hidden" );
      $("#hamburger_icon").css({
        "position" : "fixed",
        "top" : "0px",
        "right" : "0px"
      });
    }
  });
});
