require('lightbox2')
require('jquery-ui-bundle')

$(document).ready(function() {
  if($('#popup_notice').length) {
    $('#notice_off').on('click', function(){
      $('#popup_notice').fadeOut(700);
    })
    setTimeout(function(){
      $('#popup_notice').fadeIn(2000);
    }, 5000);
  }

});
