$(document).ready(function() {
  $("#search_icon").click(function() {
    $("#search_menu").show(0, function() {
      $(this).animate ({
        top: "+=200px"}, 200);
      });
    });

  $("#search_menu_off").click(function() {
    $("#search_menu").animate ({
      top: "-=200px"}, 200, function () {
        $("#search_menu").hide();
      });
    });
  });
